import axios from "axios";
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import css from "../css/Addcase.module.css";
import Compressor from "compressorjs";
import css1 from "../css/Pan.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/Loader.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/viewGame1.css";
import "../css/layout.css";
import Swal from "sweetalert2";
import Header from "../Components/Header";
import socket from "../Components/socket";
import "../css/landing.css";
import Modal from "react-bootstrap/Modal";
import { BsArrowRight, BsQrCodeScan } from "react-icons/bs";
import DownloadLink from "react-download-link";
import Spinner from "react-bootstrap/Spinner";
import SabpaisaPaymentGateway from "./SabpaisaPaymentGateway";
import QRCode from "qrcode.react";

const Addcase = ({ walletUpdate }) => {
  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++
  const [userAllData, setUserAllData] = useState();


  const userData = localStorage.getItem('udata')
  const udata = JSON.parse(userData)

    const phone = localStorage.getItem('phone')
  
 

   const [clientCode, setClientCode] = useState("RALY03");
  const [transUserName, setTransUserName] = useState("nightking41232323_15556");
  const [transUserPassword, setTransUserPassword] = useState("RALY03_SP15556");
  const [authkey, setAuthkey] = useState("b5gF6mGKDHicH6WB");
  const [authiv, setAuthiv] = useState("wD6KKUsCumUEeogG");


  // sabpay
  // alert(udata.Name.replace(/[^a-zA-Z0-9]/g,''))
  const [payerName, setPayerName] = useState(udata.Name.replace(/[^a-zA-Z0-9]/g,''));
  const [payerEmail, setPayerEmail] = useState(udata.Email);
  const [payerMobile, setPayerMobile] = useState(phone);
 
 
  const [clientTxnId, setclientTxnId] = useState(null);

  const [payerAddress, setPayerAddress] = useState("Kolkata");
  const [callbackUrl, setCallbackUrl] = useState("/sabpaisaGateway");

  const [isOpen, setIsOpen] = useState(false);


  const history = useHistory();
  let method = useRef();
  let checkInterval;

  const access_token = localStorage.getItem("token");

  const [isLoading1, setIsloading1] = useState(false);
  const [global, setGlobal] = useState(10.00);
  const [next, setNext] = useState(1);
  const [process1, setProcess] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const [isCashFreeActive, setCashFreeActive] = useState(true);
  const [isPhonePayActive, setIsPhonePayActive] = useState(false);
  const [isAirPayActive, setIsAirPayActive] = useState(false);
  const [isMypayInActive, setIsMyPayActive] = useState(false);
  const [isUpiGateway, setUpiGateway] = useState(false);
  const [isV2WalletActive, setV2Wallet] = useState(false);
  const [manualDeposit, setManualDeposit] = useState(false);
  const [isPineLabActive, setPineLabActive] = useState(false);
  const [isRazorPayActive, setRazorPayActive] = useState(false);
  const [isDecentroActive, setDecentroActive] = useState(false);
  const [isHoadaPaypayInActive, setHoadaPaypayInActive] = useState(false);
  const [ManualPaymentdata, setManualPaymentdata] = useState([]);
  const [isSubPaisaActive, setIsSubPaisaActive] = useState(false);


  const [manualData, setManualData] = useState([]);
  const [PayOneImage, setPayOneImage] = useState("");
  const [PayTwoImage, setPayTwoImage] = useState("");
  const [PayThreeImage, setPayThreeImage] = useState("");
  const [PayFourImage, setPayFourImage] = useState("");
  const [PayFiveImage, setPayFiveImage] = useState("");

  // hooks for payment url
  const [PayNow1Url, setPaynow1Url] = useState("");
  const [PayNow2Url, setPaynow2Url] = useState("");
  const [PayNow3Url, setPaynow3Url] = useState("");
  const [PayNow4Url, setPaynow4Url] = useState("");
  const [PayNow5Url, setPaynow5Url] = useState("");

  const [show_won, setShow_won] = useState(false);
  const [show_won1, setShow_won1] = useState(false);
  const handleClose_won = () => setShow_won(false);
  const [scrnshot, setScrnshot] = useState(null);
  const [fecthStatus, setFecthStatus] = useState();
  const handleShow_won = () => setShow_won(true);
  const handleShow_won1 = () => setShow_won1(true);
  const handleClose_won1 = () => setShow_won1(false);
  const [scrnshot1, setScrnshot1] = useState("");

  const [qrCode, setQrCode] = useState();
  const [walletOption, setWalletOption] = useState("airtel");
  const [bankCode, setBankCode] = useState(3003);

  const [account_mail_id, setAccount_mail_id] = useState();
  const [account_name, setAccount_name] = useState();
  const [accountPhone, setAccountPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [minLimit, setMinLimit] = useState(null);
  const [maxLimit, setMaxLimit] = useState(null);
  const [amount, setamount] = useState("");
  const [utr, setutr] = useState("");

  // console.log(maxLimit)

  const addsetting = localStorage.getItem("sitSetting");
  const addCaseSetting = JSON.parse(addsetting);

  // Hoada Hooks

  const [hoadaPay, setHoadaPay] = useState(false);


  const detail = localStorage.getItem('')

  useEffect(() => {
    setMinLimit(addCaseSetting.depositlimitMin);
    setMaxLimit(addCaseSetting.depositlimitMax);

    // if (!addCaseSetting?.isDeposit) {
    //   document.getElementById("addcase").style.display = "none";
    //   document.getElementById("depositClose").style.display = "block";
    // }
  }, []);
  // useEffect(() => {
  //   setMinLimit(addCaseSetting.depositlimitMin);
  //   setMaxLimit(addCaseSetting.depositlimitMax);
  //   setIsDeposit(addCaseSetting?.isDeposit);
  // }, [addCaseSetting]);

  const handleNext = () => {
  if(payerEmail == null){
    Swal.fire({
      title: `Invalid Email Please Update your email from profile page`,

      confirmButtonText: "OK",
      content: "custom-swal-content",
    });
  }
    else if (global < minLimit || global > maxLimit) {
      Swal.fire({
        title: `Minimum amount should be greater than ${minLimit} and maximum amount should be less than ${maxLimit}`,

        confirmButtonText: "OK",
        content: "custom-swal-content",
      });
    } else {
      setNext(2);
    }
  };

  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  //function will get called when clicked on the pay button.
  async function displayRazorpayPaymentSdk(
    channel,
    method,
    upiMethod,
    razorpay
  ) {
    const res = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. please check are you online?");
      return;
    }
    //setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    // creating a new order and sending order ID to backend
    const response = await axios.post(
      baseUrl + "user/razorpay_order",
      {
        amount: global,

        channel: channel,
        payment_method: method,
        provider: walletOption,
        bankCode: bankCode,
        account_name: account_name,
        payment_gatway: razorpay,
      },
      { headers }
    );
    if (!response) {
      alert("Server error. please check are you onlin?");
      return;
    }

    // Getting the order details back
    let order_id = response.data.txnID;
    let order_token = response.data.orderdata.id;
    const data = response.data.orderdata;
    const options = {
      key: "rzp_live_hmxBSXgEqtBqJq",
      name: "Rk Ludo",
      description: "Skill Based Game Tournament",
      order_id: data.id,
      prefill: {
        name: account_name,
        email: account_mail_id,
        contact: accountPhone,
      },
      handler: async (response) => {
        //console.log(response)
        try {
          const paymentId = response.razorpay_payment_id;

          checkrazorpaydeposit(order_id, order_token, "SUCCESS", paymentId);
        } catch (err) {
          checkrazorpaydeposit(order_id, order_token, "FAILED");
          console.log(err);
        }
      },
      theme: {
        color: "#686CFD",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const checkrazorpaydeposit = (
    order_id,
    order_token,
    order_status,
    paymentId
  ) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `razorpaydesposit/response`,
        { order_id, order_token, order_status, paymentId },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "PAID" ? "success" : "danger";
        const title =
          res.data.status == "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          localStorage.removeItem("phone");
          window.location.reload();
        }
      });
  };

  // PHONEPAY GETWAY API

  const fetchPaymentUrl = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(
        baseUrl + `phonpay-request?amount=${global}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      const data = await response.json();
      if (data) {
        setIsLoading(false);

        window.location.href = data.data.instrumentResponse.redirectInfo.url;
      }
    } catch (error) {
      console.error("Error fetching payment URL:", error);
      setIsLoading(false);
    }
  };
  // upigateway
  const upigateway = () => {

          setProcess(true)
          const access_token = localStorage.getItem('token')
          const headers = {
            Authorization: `Bearer ${access_token}`,
          }
          axios
            .post(
              baseUrl + `user/depositeupi?amount=${global.toFixed(2)}`,
              {
                amount: global,
              },
              { headers },
            )
            .then((res) => {
              if (res.data.data.status) {
                // console.log(res.data.data.data.payment_url)
                // console.log(res.data.data.data.order_id)
                let order_id = res.data.txnID
                let order_token = res.data.data.data.order_id
                //setProcess(false);
                window.open(res.data.data.data.payment_url)
                setTimeout(() => {
                  checkupideposit(order_id, order_token)
                  setProcess(false)
                }, 60000)
              } else {
                setProcess(false)
                Swal.fire({
                  title: res.data.data.msg,
                  icon: 'danger',
                  confirmButtonText: 'OK',
                })
              }
            })
            .catch((e) => {
              alert(e)
            })
       
      }
  // HOADA PAY GETWAY API
  const Hoada = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(
        baseUrl + `haodapay-request-payin?amount=${global}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${access_token}`,
          },
        }
      );

      const data = await response.json();
      if (data) {
        setTimeout(() => {
          setIsLoading(false);
        }, 5000);

        const isMobile = window.innerWidth < 768;

        if (isMobile) {
          //  window.location.href = data.data.data.intent_link;
          window.location.href = data.data.data.payment_link;
        } else {
          window.location.href = data.data.data.payment_link;
        }
      }
    } catch (error) {
      console.error("Error fetching payment URL:", error);
      setIsLoading(false);
    }
  };

  // MYPAY GETWAY API
  // const myPay = async () => {
  //   try {
  //     setIsLoading(true);

  //     const response = await fetch(
  //       baseUrl + `mypay/payin?amount=${global}`,
  //       {
  //         method:'POST',
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${access_token}`,
  //         },
  //       }
  //     );

  //     const data = await response.json();
  //     if (data) {
  //       setIsLoading(false);
  //       console.log(data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching payment URL:", error);
  //     setIsLoading(false);
  //   }
  // };

  const [upiLink, setUpiLink] = useState("");

  // const fetchUpiLink = async () => {
  //   // API call karke UPI link retrieve karein
  //   const response = await fetch("https://api.example.com/get-upi-link");
  //   const data = await response.json();
  //   setUpiLink(data.upiLink);
  // };

  const myPay = () => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `bharatpays/payin`,
        {
          amount: global,
        },
        { headers }
      )
      .then((res) => {
        console.log(res, "res");
        setProcess(false);
        if (res.data.status == false) {
          Swal.fire({
            title: res.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        } else if (res.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.paymentReferenceId;
          //setProcess(false);
          window.location.href=(res?.data?.paymentUrl)
          // setUpiLink(`${res?.data?.qrcodE_STRING}&am=${global}`);
          // handleShow_won1();
          setTimeout(() => {
            checkupideposit(order_id, order_token);
            setProcess(false);
          }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.status,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        setProcess(false);
        console.log(e, "error");

        if (e.response?.status == 400 || e.response?.status == 429) {
          Swal.fire({
            title: e.response?.data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "error!! Please refresh and connect to admin",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        alert(e);
      });
  };
  const v2Wallet = () => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `v2wallet/payin`,
        {
          amount: global,
        },
        { headers }
      )
      .then((res) => {
        console.log(res, "res");
        setProcess(false);
        if (res.data.status == false) {
          Swal.fire({
            title: res.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        } else if (res.data) {
          let order_id = res.data.txnID;
          let order_token = res.data.paymentReferenceId;
          //setProcess(false);
          window.location.href=(res?.data?.qr_string)
          // setUpiLink(`${res?.data?.qrcodE_STRING}&am=${global}`);
          // handleShow_won1();
          // setTimeout(() => {
          //   checkupideposit(order_id, order_token);
          //   setProcess(false);
          // }, 30000);
        } else {
          setProcess(false);
          Swal.fire({
            title: res.data.status,
            icon: "danger",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((e) => {
        setProcess(false);
        console.log(e, "error");

        if (e.response?.status == 400 || e.response?.status == 429) {
          Swal.fire({
            title: e.response?.data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "error!! Please refresh and connect to admin",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        alert(e);
      });
  };

  // sab pay
  const fetchPaymentUrl2 = async (e) => {
    // setPayerName(userData.Name)
    console.log(userData?.Name)
 
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .post(
        baseUrl + `subpaisa/pay`,
        {
          amount: global,
        },
        { headers }
      )
      .then((res) => {
        console.log(res.data, "res");
        setProcess(false);
        var resp = res.data;
        if (resp.status === false) {
          Swal.fire({
            title: res.data.msg,
            icon: "danger",
            confirmButtonText: "OK",
          });
        } else {
          setclientTxnId(resp.data.referenceId)
          setProcess(false);
          setIsOpen(true);
          const btn = document.getElementById('renderSabPaisa');
          btn.click();
        }
      })
      .catch((e) => {
        setProcess(false);
   
        if (e.response?.status == 400 || e.response?.status == 429) {
          Swal.fire({
            title: e.response.data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: "error!! Please refresh and connect to admin",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      
      });
    
    const btn = document.getElementById('renderSabPaisa');
    if (btn) {
      btn.click();
  }
    // history.push('/sabpaisaGateway')
  };

  const checkupideposit = (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `depositupipay/response`,
        { order_id, order_token },
        { headers }
      )
      .then((res) => {
        const icon = res.data.status == "PAID" ? "success" : "danger";
        const title =
          res.data.status == "PAID"
            ? "Deposit submited successfully"
            : "Transaction Failed";
        history.push("/");
        setTimeout(() => {
          Swal.fire({
            title: title,
            icon: icon,
            confirmButtonText: "OK",
          });
        }, 1000);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
        }
      });
  };

  //use for cashfree gatway
  const deposit = (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `user/deposite`,
        {
          amount: global,
          channel: channel,
          payment_method: method,
          provider: walletOption,
          bankCode: bankCode,
        },
        { headers }
      )
      .then((res) => {
        if (res.data.data.payment_method == "app") {
          window.location.href = res.data.data.data.url;
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        } else if (
          res.data.data.channel == "link" &&
          res.data.data.payment_method == "upi"
        ) {
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
          window.location.href = res.data.data.data.payload[upiMethod];
        } else if (
          res.data.data.channel == "qrcode" &&
          res.data.data.payment_method == "upi"
        ) {
          setQrCode(res.data.data.data.payload.qrcode);
          setProcess(false);
          setNext(3);
          checkInterval = setInterval(
            (ID) => {
              checkout(ID);
            },
            10000,
            res.data.txnID
          );
        }
      })
      .catch((e) => {
        alert(e);
      });
  };

  const checkout = (paymentID) => {
    socket.emit("getprofile");

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .post(
        baseUrl + `checkout/deposite/txn`,
        { txnID: paymentID },
        { headers }
      )
      .then((res) => {
        // alert(res.data.txnStatus)
        if (res.data.txnStatus == "PAID") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: "success",
            confirmButtonText: "OK",
          });

          setProcess(false);
          setNext(1);
          history.push("/");
        } else if (res.data.txnStatus == "FAILED") {
          walletUpdate();
          clearInterval(checkInterval);
          Swal.fire({
            title: res.data.msg,
            icon: "error",
            confirmButtonText: "OK",
          });

          setProcess(false);
          setNext(1);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUserAllData(res.data);
        setAccount_mail_id(res.data.Email);
        setAccount_name(res.data.holder_name);
        setAccountPhone(res.data.Phone);
        setPayerMobile(res.data.Phone)
      })
      .catch((e) => {
        if (e.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          history.push("/login");
        }
      });

    axios
      .get(baseUrl + `website/setting`)
      .then((res) => {
        console.log(res.data);
        setCashFreeActive(res.data.isCashFreeActive);
        setRazorPayActive(res.data.isRazorPayActive);
        setDecentroActive(res.data.isDecentroActive);
        setPineLabActive(res.data.isPineLabActive);
        setIsSubPaisaActive(res.data.isSubPaisaActive);

        setIsPhonePayActive(res.data.isPhonePayActive);
        setupi(res.data.upiId);
        setIsSubPaisaActive(res.data.isSubPaisaActive);
        setIsAirPayActive(res.data.isAirPayActive);
        setIsMyPayActive(res.data.isMypayInActive);
        setV2Wallet(res.data.isV2WalletActive);
        setUpiGateway(res.data.isUpiGateway);
        setManualDeposit(res.data.manualDeposit);
        setHoadaPaypayInActive(res.data.isHaodaPayPayInActive);
      })
      .catch((e) => {
        setCashFreeActive(false);
        setRazorPayActive(false);
        setDecentroActive(false);
        setPineLabActive(false);
        setIsPhonePayActive(false);
        setIsAirPayActive(false);
        setIsMyPayActive(false);
        setV2Wallet(false);
        setUpiGateway(false);
      });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    }
  }, []);

  // QR CODE IMAGES DOWNLOAD ---------------------------------------------------

  const handleImageDownload = (item) => {
    const link = document.createElement("a");
    link.href = item;
    link.download = "QR-Code.png"; // You can specify the downloaded file name here
    link.click();
  };
  // clear Image
  const clearImage = (e) => {
    setScrnshot1(null);
    setScrnshot(null);
  };
  // MANUAL PAYMENTS METHODS API

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    const image = e.target.files[0];
    if (image && image.size / 1000 > 300) {
      console.log(" compression");
      new Compressor(image, {
        quality: 0.6,
        success(compressedResult) {
          if (compressedResult) {
            setScrnshot(compressedResult);
          } else {
            setScrnshot(image);
          }
        },
      });
    } else {
      setScrnshot(e.target.files[0]);
    }
  };

  const ManualPayment = () => {
    const access_token = localStorage.getItem("token");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${access_token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(baseUrl + "gatewaysettings/data", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result?.isPayNowOne);
        setManualData(result);
        setPayOneImage(result?.isPayOneImage);
        setPayTwoImage(result?.isPayTwoImage);
        setPayThreeImage(result?.isPayThreeImage);
        setPayFourImage(result?.isPayFourImage);
        setPayFiveImage(result?.isPayFiveImage);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    ManualPayment();
  }, []);

  // getImage URl
  const [ImageUrl, setImageUrl] = useState("");
  const [PaymentLink, setPaymentLink] = useState("");
  const [upi, setupi] = useState("");

  const getImageUrl = (image, url) => {
    setImageUrl(image);
    setPaymentLink(url);
  };

  // code link payment

  const copyCode = (e) => {
    navigator.clipboard.writeText(upi);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Link Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  const copyCode1 = (e) => {
    navigator.clipboard.writeText(manualData?.acNo);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Account Number Copy",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const copyCode2 = (e) => {
    navigator.clipboard.writeText(manualData?.acName);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Name Copy",
      showConfirmButton: false,
      timer: 1200,
    });
  };
  const copyCode3 = (e) => {
    navigator.clipboard.writeText(manualData?.ifsccode);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "IFSC Code Copy",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  const ManualPayments = () => {
    setIsloading1(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${access_token}`);

    var formdata = new FormData();
    formdata.append("Transaction_Screenshot", scrnshot);
    formdata.append("amount", global);
    formdata.append("referenceId", utr);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(baseUrl + "manual/deposit/txn", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setIsloading1(false);

        if (result.status === "Pending") {
          Swal.fire({
            title: result.message,
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: result.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
        history.push("/");
        handleClose_won();
      })

      .catch((error) => console.log("error", error));
  };


  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer mb_space">
        <div className="pt-5 mt-5  Orher_page_main_section">
        <div className='gameCard pt-2'>
              <div className='alrt_msg_div'>
                <h5 className=''>
                  {JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                    ? ''
                    : JSON.parse(localStorage.getItem('sitSetting'))
                        ?.deposit_message}
                </h5>
              </div>
            </div>
            <>
            <section className="win_modal">
        <Modal
          show={show_won1}
          onHide={handleClose_won1}
          className="cancel_popup_reason_popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
          {upiLink && (
        <div>
          <QRCode value={upiLink} size={256} />
        </div>
         )}  
        

         <button type="button" className="close-button " onClick={handleClose_won1}>Close</button>
        
          </Modal.Body>
        </Modal>
      </section>
     
            </>
          {Boolean(!process1) && (
            <div>
              {Boolean(next == 1) && (
                <div className="">
                  <h2 className="profile_headings" style={{color:"black"}}>Choose amount to add</h2>
                  <div className="add_amount_main_box mt-4">
                    <div className="add_amount_main_box_inner">
                      <label className="label">Enter Amount</label>
                      <div className="enter_amount_input_box">
                        <span>₹</span>
                        <input
                          className="enter_amount_input"
                          type="tel"
                          id="amountInput"
                          value={global}
                          onChange={(e) => {
                            e.target.value > 0
                              ? e.target.value <= 50000
                                ? setGlobal(parseInt(e.target.value))
                                : setGlobal(50000)
                              : e.target.value < 0
                              ? setGlobal(10)
                              : setGlobal(0);
                          }}
                        />
                      </div>
                      <div className="add_amount_min_max">
                        <p>
                          {" "}
                          Min: {minLimit}, Max: {maxLimit}
                        </p>
                      </div>
                    </div>

                    {/* <div className="add_amount_buttons_main">
                      <div className="row">
                        <div className="col-4 col-lg-3 col-md-2 col-sm-3">
                          <button
                            onClick={() => {
                              setGlobal(100);
                            }}
                          >
                            <span>₹</span> 100
                          </button>
                        </div>
                        <div className="col-4 col-lg-3 col-md-2 col-sm-3">
                          <button
                            onClick={() => {
                              setGlobal(200);
                            }}
                          >
                            <span>₹</span> 200
                          </button>
                        </div>
                        <div className="col-4 col-lg-3 col-md-2 col-sm-3">
                          <button
                            onClick={() => {
                              setGlobal(500);
                            }}
                          >
                            <span>₹</span> 500
                          </button>
                        </div>
                        <div className="col-4 col-lg-3 col-md-2 col-sm-3">
                          <button
                            onClick={() => {
                              setGlobal(750);
                            }}
                          >
                            ₹ 750
                          </button>
                        </div>
                        <div className="col-4 col-lg-3 col-md-2 col-sm-3">
                          <button
                            onClick={() => {
                              setGlobal(1000);
                            }}
                          >
                            ₹ 1000
                          </button>
                        </div>
                        <div className="col-4 col-lg-3 col-md-2 col-sm-3">
                          <button
                            onClick={() => {
                              setGlobal(2000);
                            }}
                          >
                            ₹ 2000
                          </button>
                        </div>
                        <div className="col-4 col-lg-3 col-md-2 col-sm-3">
                          {" "}
                          <button
                            onClick={() => {
                              setGlobal(5000);
                            }}
                          >
                            ₹ 5000
                          </button>
                        </div>
                        <div className="col-4 col-lg-3 col-md-2 col-sm-3">
                          {" "}
                          <button
                            onClick={() => {
                              setGlobal(10000);
                            }}
                          >
                            ₹ 10000
                          </button>
                        </div>
                      </div>
                    </div> */}

                    <div className="add_cash_nest_btn">
                      {/* {!addCaseSetting.isDeposit ? ( */}
                      <button
                        type="button"
                        id="addcase"
                        className=""
                        onClick={handleNext}
                      >
                        Next <BsArrowRight className="icons" />
                      </button>
                      {/* ) : (
                        <p className="deposit_service" id="depositClose">
                          Deposit services Close for some time
                        </p>
                      )} */}
                    </div>
                  </div>
                </div>
              )}

              {Boolean(next == 2) && (
                <div className="">
                  <>
                    <div className="pb-3">
                      <div className={`${css.games_section}`}>
                        <div className="d-flex position-relative align-items-center justify-content-between">
                          <div className="add_cash_small_haedings">
                            Amount to be added ₹<b>{global}</b>
                          </div>
                        
                          <button
                            type="button"
                            onClick={() => setNext(1)}
                            className="samll_btn"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                   
                    <div className="mt-1">
                      <div className="profile_headings">
                        Pay Through QR CODE
                      </div>

              



                      {/* PHONE pay  */}
                      {/* {Boolean(isPhonePayActive) && (
                        <div
                          onClick={() => fetchPaymentUrl()}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          {isLoading ? (
                            <div
                              className="loaderReturn"
                              style={{ zIndex: "99" }}
                            >
                              <img
                                src={"/images/LandingPage_img/loader1.gif"}
                                style={{ width: "100%" }}
                              />
                            </div>
                          ) : (
                            <div
                              className="d-flex align-items-center"
                              style={{
                                backgroundColor: "#fafafa",
                                border: "1px solid #e0e0e0",
                                borderRadius: "7px",
                              }}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  height: "60px",
                                  display: "flex",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  width="40px"
                                  src="/UPI.png"
                                  alt=""
                                  style={{
                                    marginLeft: "7px",
                                    paddingBottom: "10px",
                                    paddingLeft: "3px",
                                    paddingTop: "5px",
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-center flex-column ml-4">
                                <div className="jss30">
                                  <strong>Pay Now 1</strong>
                                </div>
                                <div className="jss31"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      )} */}

                      {/* upigateway */}
                      {Boolean(isUpiGateway) && (
                        <div
                          onClick={() => upigateway()}
                          className="add-fund-box mt-3"
                          style={{ paddingTop: "0px", height: "60px" }}
                        >
                          {isLoading ? (
                            <div
                              className="loaderReturn"
                              style={{ zIndex: "99" }}
                            >
                              <img
                                src={"/images/LandingPage_img/loader1.gif"}
                                style={{ width: "100%" }}
                              />
                            </div>
                          ) : (
                            <div
                              className="d-flex align-items-center"
                              style={{
                                backgroundColor: "#fafafa",
                                border: "1px solid #e0e0e0",
                                borderRadius: "7px",
                              }}
                            >
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  height: "60px",
                                  display: "flex",
                                  textAlign: "center",
                                }}
                              >
                                <img
                                  width="40px"
                                  src="/UPI.png"
                                  alt=""
                                  style={{
                                    marginLeft: "7px",
                                    paddingBottom: "10px",
                                    paddingLeft: "3px",
                                    paddingTop: "5px",
                                  }}
                                />
                              </div>
                              <div className="d-flex justify-content-center flex-column ml-4">
                                <div className="jss30">
                                  <strong>Pay Now</strong>
                                </div>
                                <div className="jss31"></div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {/*mypay pay  */}

                      {/* {global < 2001 ? */}
                       {
  Boolean(isMypayInActive) && (
    <div
      onClick={() => myPay()}
      className="add-fund-box mt-3"
      style={{ paddingTop: "0px", height: "60px" }}
    >
      {isLoading ? (
        <div className="loaderReturn" style={{ zIndex: "99" }}>
          <img src={"/images/LandingPage_img/loader1.gif"} style={{ width: "100%" }} />
        </div>
      ) : (
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: "#fafafa",
            border: "1px solid #e0e0e0",
            borderRadius: "7px",
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{
              height: "60px",
              display: "flex",
              textAlign: "center",
            }}
          >
            <img
              width="40px"
              src="/UPI.png"
              alt=""
              style={{
                marginLeft: "7px",
                paddingBottom: "10px",
                paddingLeft: "3px",
                paddingTop: "5px",
              }}
            />
          </div>
          <div className="d-flex justify-content-center flex-column ml-4">
            <div className="jss30">
              <strong>Pay Now 2</strong>
            </div>
            <div className="jss31"></div>
          </div>
        </div>
      )}
    </div>
  )
}
                         {
  Boolean(isV2WalletActive) && (
    <div
      onClick={() => v2Wallet()}
      className="add-fund-box mt-3"
      style={{ paddingTop: "0px", height: "60px" }}
    >
      {isLoading ? (
        <div className="loaderReturn" style={{ zIndex: "99" }}>
          <img src={"/images/LandingPage_img/loader1.gif"} style={{ width: "100%" }} />
        </div>
      ) : (
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: "#fafafa",
            border: "1px solid #e0e0e0",
            borderRadius: "7px",
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{
              height: "60px",
              display: "flex",
              textAlign: "center",
            }}
          >
            <img
              width="40px"
              src="/UPI.png"
              alt=""
              style={{
                marginLeft: "7px",
                paddingBottom: "10px",
                paddingLeft: "3px",
                paddingTop: "5px",
              }}
            />
          </div>
          <div className="d-flex justify-content-center flex-column ml-4">
            <div className="jss30">
              <strong>Pay Now 3</strong>
            </div>
            <div className="jss31"></div>
          </div>
        </div>
      )}
    </div>
  )
}  
 {/* pay account  */}
 {/* {!manualData?.isAccountActive ?  (
                          <div
                            onClick={() => {
                              setNext(4);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" , cursor:"pointer"}}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/Bank.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong style={{color:"black"}}>PAY WITH ACCOUNT</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )} */}



{Boolean(manualDeposit) && (
                <>
                  {/* <div class="alrt_msg_div">
                    <h5 class="">
                      अगर कोई यूजर मैन्युअल डिपॉजिट अमाउंट जितना पे करता है उतना
                      ही पेमेंट डालकर UTR नंबर और स्क्रीनशॉट सबमिट करें अगर आप
                      पेमेंट कम या और ज्यादा डालते हो आपका किया हुआ
                      डिपॉजिट ऐड नहीं होगा |
                    </h5>
                  </div> */}
                  <h4 className="mt-4">नीचे दी हुई UPI या QR पर भुगतान करें |</h4>
                  <img
                    src={baseUrl + addCaseSetting.Logo} // Replace with the actual path to your image file
                    alt="QR Image"
                    style={{ width: "90%", height: "10%", display: "block", margin: "auto" }}
                  />
                  {/* <div>
                    <DownloadLink
                      className="results_btn results_btn_cancel mt-3"
                      style={{ width: "50%" }}
                      label=" Download QR"
                      filename={baseUrl + `${ImageUrl}`}
                      exportFile={() => "My cached data"}
                    />
                  </div> */}

                  <div className="Refer_code">
                    <h3>{manualData?.upi}</h3>

                    <button onClick={(e) => copyCode(e)}>Copy</button>
                  </div>

                  <div>
                    <button
                      className="results_btn results_btn_win mt-2"
                      onClick={handleShow_won}
                    >
                      {" "}
                      Upload Payment Details
                    </button>
                  </div>
                </>
              )}



 {
  // sabpay
  Boolean(isSubPaisaActive) && (
    <div
      onClick={() => fetchPaymentUrl2()}
      className="add-fund-box mt-3"
      style={{ paddingTop: "0px", height: "60px" }}
    >
      {isLoading ? (
        <div className="loaderReturn" style={{ zIndex: "99" }}>
          <img src={"/images/LandingPage_img/loader1.gif"} style={{ width: "100%" }} />
        </div>
      ) : (
        <div
          className="d-flex align-items-center"
          style={{
            backgroundColor: "#fafafa",
            border: "1px solid #e0e0e0",
            borderRadius: "7px",
          }}
        >
          <div
            className="d-flex align-items-center"
            style={{
              height: "60px",
              display: "flex",
              textAlign: "center",
            }}
          >
            <img
              width="40px"
              src="/UPI.png"
              alt=""
              style={{
                marginLeft: "7px",
                paddingBottom: "10px",
                paddingLeft: "3px",
                paddingTop: "5px",
              }}
            />
          </div>
          <div className="d-flex justify-content-center flex-column ml-4">
            <div className="jss30">
              <strong>Pay Now 3</strong>
            </div>
            <div className="jss31"></div>
          </div>
        </div>
      )}
    </div>
  )
}
{/* } */}



                      <div className="Qrcode">
                        {/* Qr code 1 */}
                        {manualData?.isPayNowOne ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayOneImage, PayNow1Url);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 1</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Qr code 2 */}
                        {manualData?.isPayNowTwo ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayTwoImage, PayNow2Url);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 2</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Qr code 3 */}
                        {manualData?.isPayNowThree ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayThreeImage, PayNow3Url);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 3</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Qr code 4 */}
                        {manualData?.isPayNowFour ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayFourImage, PayNow4Url);
                            }}
                            // onClick={() => fetchPaymentUrl()}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 4</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {/* Qr code 5 */}
                        {manualData?.isPayNowFive ? (
                          <div
                            onClick={() => {
                              setNext(3);
                              getImageUrl(PayFiveImage, PayNow5Url);
                            }}
                            className="add-fund-box mt-3"
                            style={{ paddingTop: "0px", height: "60px" }}
                          >
                            {isLoading ? (
                              <div
                                className="loaderReturn"
                                style={{ zIndex: "99" }}
                              >
                                <img
                                  src={"/images/LandingPage_img/loader1.gif"}
                                  style={{ width: "100%" }}
                                />
                              </div>
                            ) : (
                              <div
                                className="d-flex align-items-center"
                                style={{
                                  backgroundColor: "#fafafa",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "7px",
                                }}
                              >
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    height: "60px",
                                    display: "flex",
                                    textAlign: "center",
                                  }}
                                >
                                  <img
                                    width="40px"
                                    src="/UPI.png"
                                    alt=""
                                    style={{
                                      marginLeft: "7px",
                                      paddingBottom: "10px",
                                      paddingLeft: "3px",
                                      paddingTop: "5px",
                                    }}
                                  />
                                </div>
                                <div className="d-flex justify-content-center flex-column ml-4">
                                  <div className="jss30">
                                    <strong>QR CODE 5</strong>
                                  </div>
                                  <div className="jss31"></div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </>
                </div>
              )}

{Boolean(next == 4) && (
                <>
                  <div class="alrt_msg_div" >
                    <h5 class="">
                    अगर कोई यूजर मैन्युअल डिपॉजिट अमाउंट जितना पे करता है उतना ही पेमेंट डालकर UTR नंबर और स्क्रीनशॉट सबमिट करें अगर आप पेमेंट कम या और ज्यादा डालते हो आपका किया हुआ डिपॉजिट ऐड नहीं होगा |
                    </h5>
                  </div>
                  <div class="alrt_msg_div" style={{border:"2px solid red", height:"50px"}}>
                  <div className="Refer_code" style={{padding:"8px"}}>
                    <p style={{ fontSize:"20px"}}> 
                    Account Name :  <span style={{color:'red'}} className="mb-4">
                       {manualData?.acName}
                        </span></p>
                  </div>
                 
                  </div>
                  <div class="alrt_msg_div" style={{border:"2px solid red", height:"50px"}}>
                  <div className="Refer_code" style={{padding:"8px"}}>
                    <p style={{ fontSize:"20px"}}> 
                    Account Number :  <span style={{color:'red'}} className="mb-4">
                       {manualData?.acNo}
                        </span></p>
                    <button onClick={(e) => copyCode1(e)}>Copy</button>
                  </div>
                 
                  </div>
                  <div class="alrt_msg_div" style={{border:"2px solid red", height:"50px"}}>
                  <div className="Refer_code" style={{padding:"8px"}}>
                    <p style={{ fontSize:"20px"}}> 
                    Beneficiary Name :  <span style={{color:'red'}} className="mb-4">
                       {manualData?.acName}
                        </span></p>
                    <button onClick={(e) => copyCode2(e)}>Copy</button>
                  </div>
                 
                  </div>
                  <div class="alrt_msg_div" style={{border:"2px solid red", height:"50px"}}>
                  <div className="Refer_code" style={{padding:"8px"}}>
                    <p style={{ fontSize:"20px"}}> 
                    IFSC Code :  <span style={{color:'red'}} className="mb-4">
                    {manualData?.ifsccode}
                        </span></p>
                    <button onClick={(e) => copyCode3(e)}>Copy</button>
                  </div>
                 
                  </div>

<div>
  <p>
 NOTE :- पेमेंट सक्सेस होने के बाद निचे दिय गए बटन पर क्लिक करके स्क्रीनशॉट और UTR नंबर डालके सबमिट कर दे | 
  </p>
</div>

                  <div>
                    <button
                      className="results_btn results_btn_win mt-2"
                      onClick={handleShow_won}
                    >
                      {" "}
                      Upload Payment Details
                    </button>
                  </div>
                </>
              )} 

             
            </div>
          )}

          {Boolean(process1) && (
            <div className="loaderReturn" style={{ zIndex: "99" }}>
              <img
                src={"/images/LandingPage_img/loader1.gif"}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
      </div>

      <section className="win_modal">
        <Modal
          show={show_won}
          onHide={handleClose_won}
          className="cancel_popup_reason_popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <h3>Payment Details</h3>
              <div>
                <lable className="commaon_label">AMOUNT</lable>
                <input
                  className="commaon_input_box mb-2"
                  type="number"
                  required
                  value={global}
                  onChange={(e) => setGlobal(e.target.value)}
                  placeholder="Enter amount"
                  readOnly
                />
                <lable className="commaon_label mt-4">UTR NUMBER</lable>
                <input
                  className="commaon_input_box"
                  type="text"
                  required
                  value={utr}
                  onChange={(e) => setutr(e.target.value)}
                  placeholder="Enter UTR Number"
                />
              </div>

              <div className={`${css1.doc_upload} mt-4 upload_ss_btn`}>
                <input
                  type="file"
                  onChange={handleChange}
                  accept="image/*"
                  required
                />
                {!scrnshot && (
                  <div className="cxy flex-column position-absolute ">
                    <i
                      className="fa-solid fa-arrow-up"
                      style={{ color: "#fff" }}
                    ></i>
                    <div
                      className={`${css1.sideNav_text} mt-2 upload_ss_btn_name`}
                    >
                      Upload screenshot.
                    </div>
                  </div>
                )}
                {scrnshot && (
                  <div className={css1.uploaded}>
                    <img
                      src="/images/file-icon.png"
                      width="26px"
                      alt=""
                      style={{ marginRight: "20px" }}
                    />
                    <div
                      className="d-flex flex-column"
                      style={{ width: "80%" }}
                    >
                      <div
                        className={`${css1.name} `}
                        style={{ color: "#fff" }}
                      >
                        {scrnshot.name}
                      </div>
                      <div className={css1.size}>
                        {(scrnshot.size / 1024 / 1024).toFixed(2)} MB
                      </div>
                    </div>
                    <div className="image-block">
                      <img
                        src="/images/global-cross.png"
                        width="10px"
                        alt=""
                        onClick={() => {
                          clearImage();
                          handleClose_won();
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div style={{ width: "100%", marginTop: "20px" }}>
                <img
                  src={scrnshot1}
                  style={{ width: "100%" }}
                  className="screenshot_img"
                />
              </div>

              {/* {fecthStatus == null && fecthStatus == undefined && (
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-danger mt-3 text-white results_btn w-100"
                  id="post"
                  // onClick={(e) => {
                  //   Result(e);
                  // }}
                  onClick={ManualPayments}
                  disabled={!scrnshot}
                />
              )} */}

              {fecthStatus == null && fecthStatus == undefined && (
                <>
                  {isLoading1 ? (
                    <button className="Login-button cxy" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </button>
                  ) : (
                    <input
                      type="submit"
                      value="Submit"
                      className="btn btn-danger mt-3 text-white results_btn w-100"
                      id="post"
                      onClick={ManualPayments}
                      disabled={!utr}
                    />
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </section>

      <SabpaisaPaymentGateway clientCode={clientCode} transUserName={transUserName} transUserPassword={transUserPassword} authkey={authkey} authiv={authiv} payerName={payerName} payerEmail={payerEmail} payerMobile={payerMobile} clientTxnId={clientTxnId} amount={global} payerAddress={payerAddress} callbackUrl={callbackUrl} isOpen={isOpen} />


      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </>
  );
};

export default Addcase;
